import React, { useState, useEffect } from 'react';

import Layout from "../../components/layout"


const Index = () => {


    return (
        <Layout>
            <div>Archive</div>
        </Layout>
    )
}

export default Index
